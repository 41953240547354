import img1 from '../images/diploma1.jpg'
import img2 from '../images/dipolima2.jpg'
import img3 from '../images/dot.jpg'
import img4 from '../images/102.jpg'
import img5 from '../images/16900.jpg'
import img6 from '../images/2317.jpg'
import img7 from '../images/97.jpg'
import img8 from '../images/Cexpemapo.jpg'
import img9 from '../images/chelustnolitsevaya.jpg'
import img10 from '../images/cosmetology.jpg'

export const diploms = [
    img1, img2, img3, img4, img5, img6, img7, img8, img9, img10
]

export const BASE_URL = "https://martikainenekaterina.ru/";