import "./Services.css";
import Header from "../Header/Header";
import belly from "../../images/belly.jpg";
import blefaro from "../../images/blefaro.jpg";
import face from "../../images/krug.jpg";
import chest from "../../images/chest.jpg";
import eyes from "../../images/eyes.jpg";
import bish from "../../images/bish.jpg";
import smass from "../../images/DSC_2580.jpg";

function Services(props) {
  return (
    <section className="services">
      <Header isOpen={props.isOpen} onBurger={props.onBurger} />
      <div className="services__container">
        <div className="services__section">
          <div className="services__image"></div>
          <div className="services__qb">
            <p className="services__quote services__quote-name">
              Наша первая консультация. Как проходит и что брать с собой?
            </p>
            <p className="services__quote">
              ✔ Список анализов мне нужен. Если мы запланируем операцию, то
              анализы должны быть «свежими». А вот например, если у вас была
              травма носа, и вы планируете ринопластику, то снимки и записи
              вашего врача лучше взять с собой.
            </p>
            <p className="services__quote">
              ✔ Я хорошо отношусь к примерам. Когда пациент приходит с
              картинками и показывает, какую грудь или нос он хочет. Так мы
              понимаем эстетику и направление изменений.
            </p>
            <p className="services__quote">
              ✔ На консультации можно примерить импланты разной формы и
              размера. Посмотреть, как они будут смотреться под одеждой.
              Покрутиться перед зеркалом, как в примерочной. 3D-моделирование
              визуализирует результат ринопластики. Также можно смоделировать
              результат броулифтинга, эндоскопической подтяжки или блефаро.
            </p>
            <p className="services__quote">
              ✔ Но, важно приходить с доверием. Вам очень может нравиться
              аккуратный носик Виктории Бекхэм, но вы можете быть обладателем
              более крупных и выразительных черт лица. Такая форма на вас будет
              смотреться просто не гармонично. То же самое касается и груди. Вам
              может очень хотеться четвертый размер, но миниатюрные пропорции и
              небольшой рост не годятся для таких имплантов. Я хочу сделать
              красиво, а не так, чтобы все разглядывали ваш нос или грудь,
              потому что все остальное попросту за ними потеряется. Моя задача –
              подчеркнуть, а не перечеркнуть.
            </p>
            <p className="services__quote">
              И, конечно, я отвечу на все ваши вопросы.
            </p>
          </div>
        </div>
        <ul className="services__list">
          <li className="services__box">
            <h3 className="services__title">Абдоминопластика</h3>
            <div className="servisec__wrapper">
              <img className="services__img" alt="" src={belly}></img>
              <div className="sevices__pharagrph">
                <p className="services__description">
                  Нередко пластика живота совмещается с липосакцией, в ходе
                  которой откачивается размягченная жировая ткань (вернее, лизат
                  – эмульсия, получившаяся в результате разрушения жировых
                  клеток). Бывает, что для устранения большого живота достаточно
                  одной липосакции – когда большого избытка кожи и растянутых
                  мышц не наблюдается, и хирург рекомендует провести только
                  липосакцию.
                </p>
                <p className="services__description">
                  Абдоминопластика живота считается сложной и объемной
                  операцией, в ходе которой затрагиваются многие системы
                  организма и после которой требуется достаточно длительная
                  реабилитация. Поэтому, если хирург видит, что хороший эффект
                  можно получить без хирургического вмешательства (например, с
                  помощью липосакции и курса каких-либо аппаратных процедур), он
                  будет настаивать на том, что нужно попробовать убрать обвисший
                  живот малоинвазивными и аппаратными методами.
                </p>
              </div>
            </div>
          </li>
          <li className="services__box">
            <h3 className="services__title">Блефаропластика</h3>
            <div className="servisec__wrapper">
              <img className="services__img" alt="" src={blefaro}></img>
              <div className="sevices__pharagrph">
                <p className="services__description">
                  Блефаропластика – пластическая операция, направленная на
                  устранение возрастных изменений и дефектов, асимметрии в
                  области верхних и/или нижних век.
                </p>
                <p className="services__description">
                  До определенного возраста многие милые дамы успешно
                  поддерживают кожу век в хорошем состоянии с помощью
                  косметических средств и различных процедур. Однако после
                  сорока лет многим для сохранения молодости и устранения
                  возрастных изменений приходится прибегать к кардинальным мерам
                  – делать блефаропластику.
                </p>
                <p className="services__description">
                  Когда выполняется блефаропластика? Коррекция век может стать
                  актуальной при следующих проблемах:<br></br>
                  возрастное опущение внешних уголков глаз, глубокие складки в
                  уголках глаз;<br></br>
                  мимические и возрастные морщины вокруг глаз;<br></br>
                  обвисание кожи верхних век;<br></br>
                  дряблость кожи век;<br></br>
                  наличие жировых грыж в области век;<br></br>
                  птоз (то есть опущение одного или двух верхних век);<br></br>
                  мешки и отеки под глазами;<br></br>
                  излишки кожи на верхних и/или нижних веках;<br></br>
                  выворот или провисание нижнего века;<br></br>
                  слезные борозды (глубокие складки, проходящие от внутренних
                  уголков глаз к скуловым дугам);<br></br>
                  асимметрия век. <br></br>
                  Обычно вышеуказанные проблемы «образуются» к 35-45 годам
                  (зависит от генетических факторов и образа жизни женщины).
                  Однако в некоторых случаях проведение блефаропластики возможно
                  и в более раннем возрасте – например, при наличии жировых
                  грыж.
                </p>
              </div>
            </div>
          </li>
          <li className="services__box">
            <h3 className="services__title">Круговая подтяжка лица</h3>
            <div className="servisec__wrapper">
              <img className="services__img" alt="" src={face}></img>
              <div className="sevices__pharagrph">
                <p className="services__description">
                  Круговая подтяжка лица – пластическая операция, в ходе
                  устраняются признаки старения, «не убираемые» другими, более
                  щадящими, методами омоложения. В каких же случаях актуальны
                  малоинвазивные методики, эндоскопическая подтяжка, а в каких
                  требуется «полноценная» круговая подтяжка? Давайте
                  разбираться.
                </p>
                <p className="services__description">
                  Вообще пластические хирурги и косметологи выделяют такие
                  основные признаки старения:<br></br>
                  изменение пигментации кожи;<br></br>
                  морщины;<br></br>
                  потеря эластичности кожи;<br></br>
                  потеря «объемности» (так называемые «впалые, ввалившиеся
                  щеки»);<br></br>
                  наличие жировых отложений;<br></br>
                  обвисание кожи.<br></br>
                  Считается, что ритидэктомия дает наиболее длительный
                  результат, который исчисляется годами - обычно от 5 до 10 лет
                  в зависимости от индивидуальных особенностей пациента
                  (пациентки), образа жизни и т.д.
                </p>
                <p className="services__description">
                  Круговая подтяжка лица эффективно решает следующие
                  эстетические проблемы: подтянуть кожу лица, устранить
                  мимические и возрастные морщины; подтянуть и укрепить
                  поверхностную мышечно-апоневротическую систему; убрать
                  обвисания подбородка, щек; избавить от дряблости кожи. В
                  определенных ситуациях пластический хирург может сочетать
                  круговую подтяжку лица с липосакцией отдельных зон (например,
                  чтобы устранить «двойной» подбородок с жировыми отложениями),
                  с подтяжкой шеи.
                </p>
              </div>
            </div>
          </li>
          <li className="services__box">
            <h3 className="services__title">Маммопластика</h3>
            <div className="servisec__wrapper">
              <img className="services__img" alt="" src={chest}></img>
              <div className="sevices__pharagrph">
                <p className="services__description">
                  Основными показаниями к проведению коррекции грудных желез
                  являются:
                </p>
                <p className="services__description">
                  гипоплазия (недоразвитие) или гипертрофия молочных желез;
                  <br></br>
                  микромастия (слишком маленькие размеры) или, наоборот,
                  макромастия, гигантомастия<br></br>
                  уменьшение объемов, ухудшение формы молочных желез вследствие
                  определенных факторов (похудение, гормональный сбой и т.д.);
                  <br></br>
                  постлактационная инволюция (значительное уменьшение молочных
                  желез в размерах после грудного вскармливания);<br></br>
                  птоз (опущение) молочных желез;<br></br>
                  асимметрия молочных желез (вследствие чего грудь разной формы,
                  размеров);<br></br>
                  удаление молочной железы (частичное или полное) по медицинским
                  показаниям (при онкологии)<br></br>
                  Маммопластика – операция, которая проводится по желанию
                  пациентки. Форма и размер груди могут приносить просто
                  психологический дискомфорт (комплексы, не нравится отражение в
                  зеркале и т.д.) или стать причиной проблем со здоровьем
                  (например, слишком большая грудь вызывает одышку, нарушает
                  осанку, затрудняет передвижение). В любом случае специалисты
                  нашей клиники готовы взяться за коррекцию груди любой
                  сложности – как за увеличение, так за уменьшение, подтяжку,
                  коррекцию ареолы сосков, сочетание двух и более видов.
                </p>
              </div>
            </div>
          </li>
          <li className="services__box">
            <h3 className="services__title">Пластика век</h3>
            <div className="servisec__wrapper">
              <img className="services__img" alt="" src={eyes}></img>
              <div className="sevices__pharagrph">
                <p className="services__description">
                  Толщина кожи век составляет меньше 1 мм, и это, пожалуй, самая
                  тонкая кожа на теле человека. Сразу под кожей располагается
                  круговая орбитальная мышца, смыкающая и размыкающая веко.
                  Мышцей «управляют» нервные волокна, которые входят в нее у
                  наружную и нижнего краев и являются мелкими ответвлениями
                  лицевых нервов. Под мышцей находится глазничная перегородка
                  (соединительнотканная мембрана), далее – слой жировой
                  клетчатки, заполняющей пространство глазницы и препятствующей
                  смещению глазного яблока. Кожа верхнего века крепится к
                  мышечным волокнам и образует естественную складку при открытом
                  глазе.
                </p>
                <p className="services__description">
                  Сегодня самым эффективным способом борьбы за молодость и
                  красоту кожи век является пластическая операция. Хирургическое
                  вмешательство позволяет «за один раз» избавиться от всех
                  признаков старения и надолго забыть о них в будущем, так как
                  эффект от операции продлится до 10 лет.
                </p>
                <p className="services__description">
                  Пластика век – это коррекция, в ходе которой устраняются
                  возрастные изменения, несовершенства и дефекты в области
                  вокруг глаз. В понятие «пластика век» включается целый ряд
                  операций, в той или иной степени затрагивающих зону верхних,
                  нижних или обоих век.
                </p>
                <p className="services__description">
                  Что еще нужно знать о пластике век<br></br>
                  На сегодняшний момент пластика для век в большинстве случаев
                  проводится под местным наркозом. Окончательное решение о том,
                  какое обезболивание применять, принимает хирург. Разрез для
                  создания доступа к нужной области может выполняться
                  традиционным скальпелем или хирургическим лазерным ножом.
                  Лазер делает края разреза более аккуратными, сразу коагулирует
                  сосуды и таким образом предотвращает кровотечение. Результатом
                  пластики век становится не только улучшение состояния кожи в
                  этой области, избавление от морщин, отеков, обвисаний,
                  обретени е более открытого взгляда. Пластика век также дает
                  выраженный омолаживающий эффект всего лица. Сразу после
                  пластики век образуются гематомы и отеки. Данное явление -
                  вполне нормальная реакция организма на вмешательство, бояться
                  которой не стоит. Со временем гематомы желтеют, затем бледнеют
                  и полностью исчезают, отечность тоже постепенно уменьшается и
                  сходит. Реабилитация может продлиться от 1 до 4 недель – в
                  зависимости от разновидности и сложности проведенной
                  коррекции. После трансконъюктивальной блефаропластики
                  восстановление проходит быстрее, классическая пластика век
                  требует от пациентки большего терпения. Среди всех прочих
                  ограничений во время реабилитации самым трудным для
                  большинства пациенток является обязательный покой, который
                  следует обеспечить глазам в течение 1-2 недель. Следует
                  отказаться от чтения, пользования гаджетами, просмотра фильмов
                  и любой деятельности, которая дает нагрузку на глаза. Оценить
                  результат операции можно будет примерно через 6-8 недель. Как
                  уже говорилось выше, он сохранится весьма продолжительное
                  время – на 8-10 лет.
                </p>
              </div>
            </div>
          </li>
          <li className="services__box">
            <h3 className="services__title">Удаление комков Биша</h3>
            <div className="servisec__wrapper">
              <img className="services__img" alt="" src={bish}></img>
              <div className="sevices__pharagrph">
                <p className="services__description">
                  Комки Биша – что это такое? Это плотное скопление
                  инкапсулированных жировых комков – так называемого жирового
                  тела, расположенного в зоне под скулами между слизистой щеки и
                  кожей лица, между щечными мышцами и поверхностными лицевыми
                  мышцами и формирующего жировую ткань щек. Название «комки
                  Биша» (это общепринятый международный термин) произошло от
                  имени знаменитого французского анатома Биша (Мари Франсуа
                  Ксавье Биш).
                </p>
                <p className="services__description">
                  Основными функциями, которые выполняют комки Биша, являются:
                  <br></br>
                  обеспечение скольжения мышц лица, когда человек
                  ест,разговаривает;<br></br>
                  защита мышц лица от возможных травм;<br></br>
                  облегчение приема пищи у маленьких детей (процесса сосания).
                </p>
              </div>
            </div>
          </li>
          <li className="services__box">
            <h3 className="services__title">СМАС-лифтинг</h3>
            <div className="servisec__wrapper">
              <img className="services__img" alt="" src={smass}></img>
              <div className="sevices__pharagrph">
                <p className="services__description">
                  С возрастом изменения начинают происходить на самых глубинных
                  слоях, «под кожей». В таких ситуациях круговая подтяжка,
                  инъекции гиалоурановой кислоты, ботокс и тем более химические
                  пилинги, маски, кремы не восстановят молодость лица. Эти
                  процедуры и средства воздействуют только на верхний слой кожи
                  и не затрагивают подлежащих тканей, которые как раз вызывают
                  появление признаков старения – морщин, обвисания кожи и т.д.
                </p>
                <p className="services__description">
                  Круговая подтяжка смас считается радикальным методом борьбы с
                  таким явлением, как возрастной гравитационный птоз. Данный
                  метод позволяет вернуть на место все слои (не только кожу и
                  подкожно-жировую прослойку), к 45-50 сместившиеся вниз под
                  действием силы тяжести.
                </p>
                <p className="services__description">
                  Смас лифтинг: что это такое?<br></br>
                  Аббревиатура SMAS расшифровывается как superficial muscular
                  aponeurotic system, что в переводе означает «поверхностная
                  мышечно-апоневротическая система». Чтобы разобраться, на каком
                  уровне происходит воздействие при таком омоложении, вспомним
                  анатомию:<br></br>
                  сначала идет, как всем известно, кожа;<br></br>
                  следующий слой – подкожно-жировая клетчатка;<br></br>
                  потом находится мышечно-фиброзной слой SMAS, то есть
                  поверхностная мышечно-апоневротическая система, она соединяет
                  мышцы с дермой;<br></br>
                  мышцы;<br></br>
                  глубже располагаются только ткани надкостницы, которые
                  покрывают кости черепа. <br></br>
                  На лице SMAS присутствует в щечных и околоушных областях, а
                  также в верхней части шеи, «отвечает» за выражение эмоций и за
                  состояние мимической мускулатуры.
                </p>
                <p className="services__description">
                  Современный лифтинг SMAS предполагает комплексную подтяжку,
                  когда воздействию подвергаются все ткани: кожа, поверхностная
                  мышечно-апоневротическая система, также при необходимости
                  затрагиваются мышцы и связки лица, подкожной мышцы (платизмы)
                  шеи. И в этом заключается принципиальное отличие смас лифтинга
                  от обычной круговой ритидэктомии (подтяжки) лица, при
                  проведении которой затрагиваются только кожные и
                  подкожно-жировые ткани.
                </p>
                <p className="services__description">
                  Что дает смас лифтинг лица:<br></br>
                  возращение на место приопустившегося «каркаса» лица;<br></br>
                  разглаживание мимических и возрастных (даже глубоких) морщин;
                  <br></br>
                  фиксация апоневроза и мышц в натянутом положении, что является
                  гарантией долгосрочного эффекта;<br></br>
                  возможность скорректировать форму овала лица;<br></br>
                  визуальное омоложение лица (и шеи) на 12-15 лет.<br></br>
                  На данный момент smas лифтинг, выполненный опытным
                  профессионалом, является единственным способом восстановления
                  черт и контуров лица, возвращения молодости без получения
                  эффекта «натянутой кожи». Если хирург проводит операцию
                  правильно, со знанием анатомии лица, его кровоснабжения, с
                  учетом индивидуальных особенностей пациента, видимый результат
                  сохраняется 10-12 лет!
                </p>
                <p className="services__description">
                  Такое серьезное вмешательство требует применения общего
                  наркоза. Под местной анестезией смас лифинг лица не
                  проводится, как вариант – возможно сочетание местной анестезии
                  и «облегченной» внутривенной седации.<br></br>В ходе операции
                  аккуратно отслаивается кожный покров и SMAS со средней части
                  лица и верхней части шеи, после чего мягкие ткани, мышцы и
                  связки «ставятся на место» посредством натягивания и
                  закрепления к фасции височных костей, излишки тканей
                  благополучно иссекаются. При этом собственно подтяжка кожи
                  является всего лишь «завершающим штрихом».
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default Services;
